<template>
  <div class="w-full" data-cy="page-edit-plan">
    <div class="pb-5">
      <div
        class="header-text pb-3"
        ref="headerEditPlan"
        aria-labelledby="header for My Plan page"
        data-cy="header-plan-edit"
      >
        My Plan
      </div>
      <error-banner
        v-if="showErrors"
        :messages="errorMsgs"
        :info="errorMsgs.length === 0"
        :error="errorMsgs.length > 0"
        class="mb-5"
      >
        <template v-slot:title>
          <div
            :class="{
              'font-semibold text-lg': true,
              'mb-3 pb-1 border-b': 0 !== errorMsgs.length,
            }"
          >
            <div v-if="errorMsgs.length > 0">
              Missing values for the following fields
            </div>
            <div v-else>PPDP Plan Data Valid</div>
          </div>
        </template>
        <template v-slot:body>
          <div class="flex items-center justify-start" v-if="validating">
            <div><font-awesome-icon icon="spinner" spin /></div>
          </div>
        </template>
      </error-banner>

      <div class="grid grid-cols-3">
        <PlanWizardStepTracker
          :items="steps"
          :current_step="current_step.index"
          class="col-span-2"
        />
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 grid-rows-2 gap-6">
      <div class="box-style col-span-2 row-span-2">
        <div
          class="secondhead-text"
          v-text="`${current_step.index}.  ${current_step.title}`"
        />
        <div class="text-xl pb-2" v-text="current_step.description" />
        <div class="border border-cool-gray my-3"></div>

        <PlanWizard
          :current_plan_step="current_step.index"
          :mentor_id="mentor_id"
          class="pb-3"
          ref="wizard"
          @save-progress="saveProgress"
          @errors="handleErrors"
          v-if="!this.loading"
        />
        <div v-else>
          <div class="flex items-center justify-center py-3">
            <font-awesome-icon icon="spinner" spin />
            <p class="ml-2">LOADING</p>
          </div>
        </div>
        <div
          class="border border-cool-gray my-2"
          v-if="current_step_index !== steps[steps.length - 1].index - 1"
        ></div>
        <div class="relative h-14">
          <Modal
            v-if="show_submit_modal"
            class="z-10 w-2/3 mx-auto right-0"
            label="Are you sure you want to submit your plan?"
            submit_btn_text="Yes, I am ready to submit my plan"
            show_cancel
            cancel_btn_text="No, continue editing"
            @submit="submit_plan()"
            @close="show_submit_modal = !show_submit_modal"
          >
            <template v-slot:content>
              <p>
                You must meet with your mentor to review your plan before you
                submit your plan.
              </p>
            </template>
          </Modal>
          <div class="absolute bottom-0 right-0">
            <router-link v-if="current_step_index === 0" to="/plan">
              <button
                class="inline-block mr-6 py-1.5 px-6 bg-white border-dark-purple border rounded-full text-dark-purple hover:text-white hover:bg-dark-purple"
              >
                Cancel
              </button>
            </router-link>
            <button
              v-if="current_step_index >= 1"
              class="inline-block mr-6 py-1.5 px-6 bg-white border-dark-purple border rounded-full text-dark-purple hover:text-white hover:bg-dark-purple"
              @click="navigate('back')"
            >
              Back
            </button>
            <button
              class="inline-block py-1.5 px-6 bg-purple hover:bg-dark-purple rounded-full text-white"
              @click="
                navigate(
                  current_step_index === steps[steps.length - 1].index - 1
                    ? 'submit'
                    : 'next'
                )
              "
              data-cy="button-next-submit"
            >
              {{
                current_step_index === steps[steps.length - 1].index - 1
                  ? "Submit"
                  : "Next"
              }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-span-1 row-span-1">
        <div class="box-style bg-light-gray" v-if="current_step.hint">
          <div class="secondhead-text pb-2">{{ current_step.hint.title }}</div>
          <div
            v-for="item in current_step.hint.items"
            :key="item"
            class="pb-1 text-lg"
            v-html="item"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlanWizard from "@/components/displays/student-displays/milestones-plan/plan-wizard/PlanWizard";
import PlanWizardStepTracker from "@/components/displays/student-displays/milestones-plan/plan-wizard/PlanWizardStepTracker";
import {mapActions, mapGetters, mapState} from "vuex";
import Modal from "@/components/modal/Modal";
import ErrorBanner from "@/components/forms/ErrorBanner";

export default {
  name: "PlanEdit",
  components: { Modal, PlanWizardStepTracker, PlanWizard, ErrorBanner },
  mounted() {
    this.$refs.headerEditPlan.focus();
  },
  data() {
    return {
      loading: true,
      current_step_index: 0,
      show_submit_modal: false,
      showErrors: false,
      errorMsgs: [],
      mentor_id: null,
      steps: [
        {
          index: 1,
          title: "Plan Details",
          description: "Please provide details for your plan.",
          hint: {
            title: "Sample PPDP",
            items: [
              "Find guidance in the Mentor X Program Policy Manual where you can reference a sample of what a PPDP looks like.",
              `<a href="https://one.stthomas.edu/sites/schools-colleges/school-of-law/SitePage/33159/mentor-externship" target="_blank" class="text-dark-purple underline hover:cursor-download"> Open the manual</a>`,
            ],
          },
        },
        {
          index: 2,
          title: "Select Experiences",
          description:
            "Identify experiences that you're interested in doing this year from the categories below.",
          hint: {
            title: "Identifying Experiences",
            items: [
              "Research your mentor and look up their practice areas to see what experiences apply.",
              "Find experiences that you're interested in doing with other lawyers or judges.",
            ],
          },
        },
        {
          index: 3,
          title: "Complete Ethics Certification",
          description:
            "Read the following certifications carefully and complete these certifications only if you have done what the certifications call for.",
        },
        {
          index: 4,
          title: "Review and Print",
          description:
            "Click Review to read over your plan. If you have reviewed your plan with your mentor and made any necessary edits, click Next to submit your plan",
          hint: {
            title: "When is my plan ready to submit? ",
            items: [
              "After you meet with your mentor, make any changes to your plan and submit your plan. ",
            ],
          },
        },
        {
          index: 5,
          title: "Submit Plan",
          description:
            "Now that you have met with your mentor and reviewed your plan, make any changes you discussed to your plan and submit your plan.",
        },
      ],
    };
  },
  created() {
    if (
      this.$store.state.user_selected_term.name !==
      this.lookup_current_term.name
    ) {
      this.$router.push("/plan/view");
    }
    this.mentor_id = this.$route.params.mentor_id;
    this.loading = false;
  },
  watch: {
    acad_level(new_acad_lvl) {
      this.fetchStudentMentors(new_acad_lvl).then(
        this.fetchPlanResource(new_acad_lvl)
      );
    },
  },
  methods: {
    handleErrors(errors) {
      this.showErrors = false;
      this.errorMsgs = errors;
      if (this.errorMsgs.length) {
        this.showErrors = true;
      }
    },
    navigate(direction) {
      this.$refs.wizard.validate();
      if (this.errorMsgs.length === 0) {
        this.$refs.wizard.saveProgress();
        switch (direction) {
          case "back":
            this.current_step_index < 1
              ? this.$router.go(-1)
              : this.current_step_index--;
            break;
          case "next":
            this.current_step_index++;
            break;
          case "submit":
            this.show_submit_modal = true;
        }
      }
    },
    saveProgress(local_plan, draft = true) {
      local_plan.status = draft ? "DRAFT" : "COMPLETE";
      if (
        this.plan.plans[this.mentor_id] &&
        this.plan.plans[this.mentor_id].ppdp_id
      ) {
        local_plan.ppdp_id = this.plan.plans[this.mentor_id].ppdp_id;
        this.putPlanResource(local_plan).then(() =>
          localStorage.setItem("lastSavedPlanIndex", this.current_plan_step)
        );
      } else {
        this.postPlanResource(local_plan).then(() =>
          localStorage.setItem("lastSavedPlanIndex", this.current_plan_step)
        );
      }
    },
    submit_plan() {
      if (this.errorMsgs.length === 0) {
        this.saveProgress(this.plan.plans[this.mentor_id], false);
        this.$router.push("/plan/success");
      }
    },
    ...mapActions([
      "fetchStudentMentors",
      "putPlanResource",
      "postPlanResource",
      "fetchPlanResource",
    ]),
  },
  computed: {
    ...mapGetters(["lookup_current_term"]),
    ...mapState({
      acad_level: (state) => state.acad_level,
      plan: (state) => state.plan,
    }),
    current_step() {
      return this.steps[this.current_step_index];
    },
  },
};
</script>

<style scoped></style>
